import Vue from 'vue'
import { isPlainObject, isError, isEmpty } from 'lodash'

/**
  * namespace errors with unique identifier for getting & deleting
  */
const ERROR = 'error'
const SUCCESS = 'success'

export default {
  namespaced: true,
  state: {
    // namespaced on Date.now
    messages: {},
  },
  getters: {
    messagesList (state) {
      return Object.values(state.messages)
    },
    errorsList (state, getters) {
      return getters.messagesList.filter((message) => message.type === ERROR)
    },
    successesList (state, getters) {
      return getters.messagesList.filter((message) => message.type === SUCCESS)
    },
    hasErrors (state, getters) {
      return getters.errorsList.length > 0
    },
    hasMessages (state, getters) {
      return getters.messagesList.length > 0
    },
    lastError (state, getters) {
      return getters.errorsList[getters.errorsList.length - 1]
    },
    lastMessage (state, getters) {
      return getters.messagesList[getters.messagesList.length - 1]
    }
  },
  mutations: {
    ADD_MESSAGE (state, message) {
      console.log('ADD_MESSAGE', message)
      Vue.set(state.messages, message.id, message)
    },
    CLEAR_MESSAGE (state, id) {
      Vue.delete(state.messages, id)
    },
    CLEAR_MESSAGES (state) {
      Vue.set(state, 'messages', {})
    },
    DELETE_MESSAGE (state, id) {
      Vue.delete(state.messages, id)
    }
  },
  actions: {
    ADD_MESSAGE ({ commit }, message) {
      commit('ADD_MESSAGE', message)
      if (message.type === SUCCESS) {
        setTimeout(() => commit('DELETE_MESSAGE', message.id), 5000)
      }
    },
    ADD_ERROR ({ dispatch }, error) {
      console.log('[ADD_ERROR]', error)
      const keys = Object.keys(error)
      if (
        isError(error) ||
        keys.includes('detail') ||
        keys.includes('message')
      ) {
        // Error: { message: "error message" } || { "detail": "error message" } || { "message": "error message" }
        dispatch('ADD_MESSAGE', {
          id: Date.now().toString(),
          message: error.detail || error.message || error.errorMessage,
          type: ERROR,
        })
      } else if (error.hcs) {
        // support for editor errors
        // { hcs: [{hotel_component_param_set: []}] }
        const hcs = error.hcs.find(obj => {
          return obj.hotel_component_param_set
        })
        const hcsError = hcs.hotel_component_param_set || error
        dispatch('HANDLE_ERRORS', hcsError)
      } else if (isPlainObject(error)) {
        // { "something": ["something else"], "another": ["another thing"] }
        dispatch('HANDLE_ERRORS', error)
      } else {
        console.warn('check type of error passed to ADD_ERROR')
      }
    },
    ADD_SUCCESS ({ commit, dispatch }, success) {
      commit('CLEAR_MESSAGES')
      dispatch('ADD_MESSAGE', {
        id: Date.now().toString(),
        message: success.message,
        type: SUCCESS,
      })
    },
    CLEAR_MESSAGE ({ commit }, id) {
      commit('CLEAR_MESSAGE', id)
    },
    // takes error object { err_key: ['Err msg'] } -> Err key: 'err msg'
    HANDLE_ERRORS ({ dispatch }, errors) {
      Object.entries(errors).forEach(([key, messages]) => {
        if (Array.isArray(messages)) {
          messages.forEach(message => {
            const prettyKey = key.replace(/_/g, ' ').toUpperCase()
            const prettyMessage = `${prettyKey}: ${message}`
            dispatch('ADD_ERROR', new Error(prettyMessage))
          })
        } else if (isEmpty(messages)) {
          // do nothing
        } else if (isPlainObject(messages)) {
          dispatch('HANDLE_ERRORS', messages)
        } else {
          console.error(`Cannot handle errors as type: ${typeof messages}`)
        }
      })
    },
  }
}
