/**
 * for nested parsing use an object as follows:
 * {
 *  key: string,
 *  schema: object
 * }
 * where the key is the incoming key to parse and
 * the schema is the object to pass to ParserUtils.parseWithSchema
 */

const inverseDag = {
  id: 'id',
  parent: 'parent',
  get children () {
    return {
      schema: inverseDag,
      key: 'children'
    }
  },
  cta_link: 'ctaLink',
  cta_reward_not_before_time: 'ctaRewardNotBeforeTime',
  cta_reward_expiration_time: 'ctaRewardExpirationTime',
  event_type: 'triggerTypeId',
  param: 'param',
  reward_algorithm: 'rewardAlgoId',
  state: 'state',
  template: 'emailDesignId',
  workflow_enabled: 'workflowEnabled',
  event_campaign: 'campaignId',
  operator: 'operator',
  isPromotion: 'is_promotion'
}

const dag = {
  id: 'id',
  uuid: 'uuid',
  parent: 'parent',
  get children () {
    return {
      schema: dag,
      key: 'children'
    }
  },
  created: 'created',
  modified: 'modified',
  ctaLink: 'cta_link',
  ctaRewardNotBeforeTime: 'cta_reward_not_before_time',
  ctaRewardExpirationTime: 'cta_reward_expiration_time',
  campaignId: 'event_campaign',
  triggerTypeId: 'event_type',
  param: 'param',
  rewardAlgoId: 'reward_algorithm',
  state: 'state',
  emailDesignId: 'template',
  emailDesignName: 'template_name',
  displayName: 'display_name',
  workflowEnabled: 'workflow_enabled',
  operator: 'operator',
  isPromotion: 'is_promotion'
}
/**
 * these are triggers for event based campaigns
 */
const campaignEventType = {
  id: 'id',
  logic: 'logic',
  name: 'name',
  copy: 'copy',
  copyForOperatorNot: 'copy_for_operator_not',
  type: 'type',
  category: 'category'
}

export const schemas = {
  hotel: {
    hotelCode: 'hotel_code',
    name: 'name',
    id: 'id',
    hotelGroupId: 'hotel_group_id',
  },
  cradleCognitoHotel: {
    campaignCost: 'campaign_cost',
    hotelCode: 'api_name',
    name: 'name',
    id: 'legacy_id',
    cradleId: 'id',
    hotelGroupId: 'hotel_group',
    active: 'active',
    senderEmail: 'sender_email',
    productType: 'product_type',
    isVanityProperty: 'is_corporate_level',
    hotelGroupHotelIds: 'hotel_group_hotel_ids',
    showOprmPostBookingRewardFeatures: 'show_oprm_post_booking_reward_features',
    loyaltyPortalUrl: 'loyalty_portal_url'
  },
  cradleHotel: {
    apiName: 'api_name',
    name: 'name',
    id: 'id',
    legacyId: 'legacy_id',
    homepageHost: 'homepage_host',
    bookingEngineHost: 'booking_engine_host',
    bookingEngine: 'booking_engine',
    funnelUxs: 'funnel_uxs',
    hotelGroup: 'hotel_group',
    region: 'region',
    image: 'logo_image',
    address: 'address',
  },
  funnelUx: {
    channel: 'channel',
    context: 'context',
    enabled: 'enabled',
    name: 'name',
    uuid: 'uuid',
  },
  group: {
    name: 'group',
    id: 'id',
  },
  merchant: {
    id: 'id',
    name: 'name',
    image: 'image',
    bookingEngine: 'booking_engine',
    bookingEngineCustomProvider: 'booking_engine_custom_provider',
  },
  promotion: {
    id: 'id',
    uuid: 'uuid',
    promotionCategory: 'promotion_category',
    destinationGeotargets: 'destination_geotargets',
    termsAndConditions: 'terms_and_conditions',
    redemptionMethod: 'redemption_method',
    redemptionInstructions: 'redemption_instructions',
    cost: 'cost',
    value: 'value',
    division: 'division',
    isBrandSafe: 'is_brand_safe',
    merchant: 'merchant',
    merchantName: 'merchant_name',
    creativeConfigs: 'reward_creative_configs',
    active: 'active',
    created: 'created',
    // image upload needs to be on top level for multipart
    thumbnailImage: 'thumbnail_image',
    onPropertyHotels: 'on_property_hotels',
    modified: 'modified',
    reusablePromoCode: 'reusable_promo_code',
    redemptionUrl: 'redemption_url',
    expiryType: 'expiry_type',
    redemptionExpiration: 'redemption_expiration',
    requiresNotice: 'requires_notice',
    seasonal: 'is_seasonal',
    seasonStart: 'season_start_date',
    seasonEnd: 'season_end_date',
    specificExpirationDate: 'specific_expiration_date',
  },
  promotionNestedFields: {
    destinationGeotargets: 'destination_geotargets',
    creativeConfigs: 'reward_creative_configs',
  },
  creativeConfigs: {
    id: 'id',
    thumbnailText: 'thumbnail_text',
    thumbnailImage: 'thumbnail_image',
    promotedIcon: 'promoted_icon',
    promotedText: 'promoted_text',
    share: 'share',
  },
  promotionCategories: {
    entertainment: 'Entertainment',
    travel: 'Travel',
    shopping: 'Shopping',
    healthAndWellness: 'Health & Wellness',
    foodAndBeverage: 'Food & Beverage',
    tours: 'Tours',
  },
  redemptionMethods: {
    'Reusable code': 're',
    'Single use code': 'su',
    Evoucher: 'vo',
    'Landing page': 'lp',
  },
  oprmRedemptionMethods: {
    'Reusable code': 're',
    // 'Single use code': 'su',
    // 'Evoucher': 'vo',
    'Redemption link': 'lp',
    'Tap to Redeem': 'ip',
  },
  divisions: {
    local: 'lo',
    national: 'na',
    onProperty: 'op',
  },
  destinationGeotarget: {
    hashid: 'hashid',
    name: 'name',
  },
  bookingEngine: {
    id: 'id',
    name: 'name',
  },
  emailMarketingRewardAlgorithm: {
    params: 'algorithm_params',
    identifier: 'identifier',
    maxRewardSelections: 'max_reward_selections',
    onPropertyRewards: 'on_property',
    marketPlaceRewards: 'brand_safe',
    hotelId: 'hotel',
    modified: 'modified',
    id: 'id',
  },
  hotelRewardAlgorithm: {
    params: 'algorithm_params',
    identifier: 'identifier',
    onPropertyRewards: 'on_property',
    maxRewardSelections: 'max_reward_selections',
    marketPlaceRewards: 'brand_safe',
    hotelId: 'hotel',
    modified: 'modified',
    id: 'id',
    share: 'share',
  },
  emailDesign: {
    id: 'id',
    name: 'name',
    emailSubject: 'email_subject',
    hotelId: 'hotel',
    modified: 'modified',
    created: 'created',
    readAccessHotelIds: 'read_access_hotels',
    htmlUrl: 'template_html',
    json: 'template_json',
    type: 'template_type',
    rewardsDisplayLimit: 'rewards_display_limit'
  },
  dag,
  individiualCampaignEventNode: {
    ...dag,
    /**
     * do not want to recursively parse campaign node event objects
     * when creating/updating individual nodes
     */
    children: 'children'
  },
  campaign: {
    id: 'id',
    dag: {
      key: 'dag',
      schema: dag
    },
    name: 'name',
    hotelId: 'hotel',
    segmentIds: 'segments',
    state: 'state',
    created: 'created',
    modified: 'modified',
    active: 'active',
    type: 'campaign_type',
    workflowEnabled: 'workflow_enabled'
  },
  campaignEventType,
  segment: {
    id: 'id',
    name: 'name',
    hotel: 'hotel',
    description: 'description',
    definition: 'definition',
    definitionType: 'definition_type',
    lastRunTime: 'last_run_time',
  },
  inverseDag,
}
