




























































import { storageUtils } from '@/utils/storage'
import { DrawerLink } from '@/types'
import { getShow } from '@/utils/getShow'

export type Storage = 'sessionStorage' | 'localStorage'

export interface StorageConfig {
  key: string;
  storage: Storage;
}

export default {
  name: 'DrawerItem',
  props: {
    link: {
      type: Object,
      required: true,
      default: () => ({
        copy: '',
        routeName: '',
        icon: '',
        children: []
      })
    }
  },
  data () {
    return {
      expanded: false,
      icons: {
        expand: ['fas', 'angle-right'],
        minimize: ['fas', 'angle-down'],
        lock: ['fas', 'lock'],
      },
    }
  },
  computed: {
    toggleIcon (): string[] {
      return this.expanded ? this.icons.minimize : this.icons.expand
    },
    storageKey (): string {
      return `drawer-item-${this.link.copy}`.toLowerCase()
    },
    storageConfig (): StorageConfig {
      return {
        key: this.storageKey,
        storage: 'sessionStorage'
      }
    },
    showDropdown (): boolean {
      if (this.getDisabled(this.link)) {
        return false
      }
      return this.link.children && this.link.children.length > 0
    },
  },
  methods: {
    getShow,
    getActive (link: DrawerLink): boolean {
      /* navigating to subroutes like campaigns-new
         should set `campaigns` link to active
      */
      return this.isCurrentRoute(link) || this.isSubRoute(link)
    },
    getDisabled (link: DrawerLink): boolean {
      if (link.disabled) {
        return link.disabled()
      }
      return false
    },
    toggleExpanded (): void {
      this.expanded = !this.expanded
      this.storeExpanded({
        ...this.storageConfig,
        value: this.expanded
      })
    },
    storeExpanded: storageUtils.set,
    setupExpanded: storageUtils.getWithInitialValue,
    isCurrentRoute (link: DrawerLink): boolean {
      return this.$route.name === link.routeName
    },
    isSubRoute (link: DrawerLink): boolean {
      const currentRoute = this.$route.name
      const re = new RegExp(`^${link.routeName}-`, 'gi')
      return re.test(currentRoute)
    }
  },
  beforeMount (): void {
    if (this.showDropdown) {
      this.expanded = this.setupExpanded({
        ...this.storageConfig,
        initialValue: true,
      })
    }
  }
}
