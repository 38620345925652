import Vue from 'vue'
import axios from 'axios'
import { cloneDeep } from 'lodash'
import { EditorUtils } from '@/editor/utils'
import { createFormData } from '@/utils/misc'
import { ComponentParam, TemplateFieldPermission, InitialComponentParam } from '@/types'
import { parseGroupConfig } from '@/parsers'

const { hotelId } = EditorUtils.getEditorParams()

const POST = 'post'
const PATCH = 'patch'

export type ImageComponentParamResponse = {
  error: unknown;
  data: undefined | { field_value: string | number };
  param: ComponentParam;
}

/**
 * @important
 * the group config contains template objects with nested component params and template field permissions.
 * state.component params contains all component params namespaced by category.
 * state.templateFieldPermissions contains all template field permissions namespaced by category.
 * editor fields are bound to the values in these two objects.
 * the original component params and template field permissions are not deleted from state.config but they
 * are replaced when creating the paylod sent to the loyalty portal.
 *
 * there are three requests made when saving changes to the database. those requests are organized as follows:
 * 1. the group config image fields (favicon, logo)
 * 2. the component params with `image` as category
 * 3. all group config fields and component params that are not images
 */

// TODO type state
export default {
  namespaced: true,
  name: 'loyaltyPortalEditor',
  state: {
    designChanges: {
      componentParams: false,
      imageComponentParams: false,
      imageFields: false,
    },
    groupUUID: undefined,
    groupConfig: undefined,
    componentParams: {} as Record<string, Record<string, ComponentParam>>,
    initialComponentParams: {} as Record<string, Record<string, InitialComponentParam>>,
    templateFieldPermissions: {} as Record<string, Record<string, TemplateFieldPermission>>,
    otherComponents: [] as object[],
    preferenceComponents: [] as object[],
    hotelId,
  },
  getters: {
    /**
     * @note building payload to be consumed by loyalty portal morphism schema
     */
    LOYALTY_PORTAL_CONFIG_PAYLOAD (state) {
      /**
       * @param {boolean} excludeFiles will not include component params where field_value is a File
       */
      return ({ excludeFiles }) => {
        const config = cloneDeep(state.groupConfig)

        const params = {}

        Object.values(state.componentParams).forEach(paramDict => {
          Object.values(paramDict).forEach(param => {
            // do not include params with undefined field_value
            if (param.field_value === undefined) {
              return
            }

            if (excludeFiles && param.field_value instanceof File) {
              return
            }

            if (!params[param.__temporary_component_id__]) {
              params[param.__temporary_component_id__] = []
            }

            const clonedParam = { ...param }
            delete clonedParam.__temporary_component_id__
            delete clonedParam.default_value
            delete clonedParam.required

            params[param.__temporary_component_id__].push(clonedParam)
          })
        })

        Object.keys(params).forEach(componentId => {
          const component = config.customer_portal_component.find(_component => {
            return _component.id === parseInt(componentId)
          })
          if (component) {
            component.customer_portal_component_param_set = params[componentId]
          }
        })

        console.log('[LOYALTY_PORTAL_CONFIG_PAYLOAD] config', config)

        return config
      }
    },
    GET_FIELD_ID (state) {
      return (param) => {
        try {
          return state.templateFieldPermissions[param.field_category][param.field_name].field
        } catch (e) {
          console.log('[GET_FIELD_ID error]', e)
          return null
        }
      }
    },
    GROUP_CONFIG_IMAGE_FIELDS_PAYLOAD (state) {
      return () => {
        const fields = ['favicon_image', 'logo_image']

        const payload = {}
        fields.forEach(field => {
          if (state.groupConfig[field] instanceof File) {
            payload[field] = state.groupConfig[field]
          }
        })

        return payload
      }
    },
    /**
     * @important include all the original non-portal components to avoid being deleted in patch request
     */
    COMPONENT_PARAMS_PAYLOAD (state, getters) {
      return () => {
        return {
          customer_portal_component: [
            ...getters.LOYALTY_PORTAL_CONFIG_PAYLOAD({ excludeFiles: true }).customer_portal_component,
            ...state.otherComponents,
            ...state.preferenceComponents
          ]
        }
      }
    }
  },
  mutations: {
    SET_LOYALTY_PORTAL_GROUP_UUID (state, uuid) {
      console.log('[SET_LOYALTY_PORTAL_GROUP_UUID]', uuid)
      state.groupUUID = uuid
    },
    SET_GROUP_CONFIG (state, config) {
      console.log('[SET_GROUP_CONFIG]', config)
      state.groupConfig = cloneDeep(config)
    },
    SET_COMPONENT_PARAMS (state, params) {
      console.log('[SET_COMPONENT_PARAMS]', params)
      state.componentParams = params
    },
    SET_INITIAL_COMPONENT_PARAMS (state, params) {
      console.log('[SET_INITIAL_COMPONENT_PARAMS]', params)
      state.initialComponentParams = cloneDeep(params)
    },
    SET_TEMPLATE_FIELD_PERMISSIONS (state, permissions) {
      console.log('[SET_TEMPLATE_FIELD_PERMISSIONS]', permissions)
      state.templateFieldPermissions = permissions
    },
    SET_GROUP_CONFIG_FAVICON_IMAGE (state, value) {
      console.log('SET_GROUP_CONFIG_FAVICON_IMAGE]', value)
      Vue.set(state.groupConfig, 'favicon_image', value)
    },
    SET_GROUP_CONFIG_LOGO_IMAGE (state, value) {
      console.log('[SET_GROUP_CONFIG_LOGO_IMAGE]', value)
      Vue.set(state.groupConfig, 'logo_image', value)
    },
    SET_COMPONENT_PARAM_FIELD_VALUE (state, { category, fieldName, value }) {
      console.log('[SET_COMPONENT_PARAM_FIELD_VALUE]', category, fieldName, value)
      Vue.set(
        state.componentParams[category][fieldName],
        'field_value',
        value
      )
      console.log('[SET_COMPONENT_PARAM_FIELD_VALUE] field_value after mutation', state.componentParams[category][fieldName].field_value)
    },
    SET_GROUP_CONFIG_VALUE (state, { fieldName, value }) {
      Vue.set(
        state.groupConfig,
        fieldName,
        value
      )
    },
    SET_DESIGN_CHANGES (state, { field, value }) {
      console.log('[SET_DESIGN_CHANGE]', field, value)
      Vue.set(state.designChanges, field, Boolean(value))
    },
    SET_OTHER_COMPONENTS (state, components) {
      state.otherComponents = components
    },
    SET_PREFERENCE_COMPONENTS (state, components) {
      state.preferenceComponents = components
    }
  },
  actions: {
    // not async on purpose
    UPDATE_GROUP_CONFIG ({ dispatch, state }) {
      if (state.designChanges.imageComponentParams) {
        dispatch('CREATE_OR_UPDATE_IMAGE_COMPONENT_PARAMS')
      }
      if (state.designChanges.imageFields) {
        dispatch('CREATE_OR_UPDATE_GROUP_CONFIG_IMAGE_FIELDS')
      }
      if (state.designChanges.componentParams) {
        dispatch('UPDATE_COMPONENT_PARAMS')
      }
    },
    async CREATE_OR_UPDATE_IMAGE_COMPONENT_PARAMS ({ rootState, state, getters, dispatch, commit }) {
      const requestPromises = []
      /**
       * create payload and network req wrapped in Promise to be resolved by Promise.all
       */
      Object.values(state.componentParams.image)
        .filter((param: ComponentParam) => param.field_value instanceof File)
        .forEach((param: ComponentParam) => {
          const payload = createFormData({
            customer_portal_component: param.__temporary_component_id__.toString(),
            field_value: (param.field_value as string | Blob),
            field: getters.GET_FIELD_ID(param),
          })

          let method
          let endpoint

          if (param.id) {
            method = PATCH
            endpoint = `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/customer-portal-param-files/${param.id}/`
          } else {
            method = POST
            endpoint = `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/customer-portal-param-files/`
          }

          const req = new Promise((resolve) => {
            try {
              axios[method](
                endpoint,
                payload,
                {
                  headers: {
                    Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
                  }
                })
                .then((resp: { data: unknown; error: unknown }) => {
                // include param in resolved object so that after a successful request is made
                // we can update the field_value in state.componentParams
                  resolve({ param, data: resp.data, error: undefined })
                })
                .catch(error => {
                  resolve({ error, data: undefined, })
                })
            } catch (error) {
              resolve({ error, data: undefined, })
            }
          })

          requestPromises.push(req)
        })
      console.log('[CREATE_OR_UPDATE_IMAGE_COMPONENT_PARAMS] reqs', requestPromises)

      const errors = []
      const responses = await Promise.all(requestPromises)
      responses.forEach((resp: ImageComponentParamResponse) => {
        if (resp.error) {
          errors.push(resp.error)
          dispatch('messages/ADD_ERROR', resp.error, { root: true })
        } else {
          commit('SET_COMPONENT_PARAM_FIELD_VALUE', {
            category: resp.param.field_category,
            fieldName: resp.param.field_name,
            value: resp.data.field_value
          })
        }
      })

      if (errors.length === 0) {
        commit('SET_DESIGN_CHANGES', { field: 'imageComponentParams', value: false })
      }
    },
    async CREATE_OR_UPDATE_GROUP_CONFIG_IMAGE_FIELDS ({ rootState, state, dispatch, commit, getters }) {
      const rawPayload = getters.GROUP_CONFIG_IMAGE_FIELDS_PAYLOAD()
      console.log('[CREATE_OR_UPDATE_GROUP_CONFIG_IMAGE_FIELDS] rawPayload', rawPayload)

      const payloadKeys = Object.keys(rawPayload)

      if (payloadKeys.length === 0) return

      const endpoint = `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/customer-portal-config-files/${state.groupUUID}/`
      const payload = createFormData(rawPayload)

      let resp
      let error

      try {
        resp = await axios.patch(
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
            }
          }
        )
      } catch (e) {
        error = e
      }

      console.log('[CREATE_OR_UPDATE_GROUP_CONFIG_IMAGE_FIELDS]', resp, error)
      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        // all image fields are returned in response, this will only update state with fields that were changed
        payloadKeys.forEach(key => {
          commit('SET_GROUP_CONFIG_VALUE', { fieldName: key, value: resp.data[key] })
        })
        commit('SET_DESIGN_CHANGES', { field: 'imageFields', value: false })
      }
    },
    async UPDATE_COMPONENT_PARAMS ({ rootState, state, dispatch, getters, commit }) {
      let resp
      let error

      const endpoint = `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/customer-portal-config/${state.groupUUID}/`
      const payload = getters.COMPONENT_PARAMS_PAYLOAD()
      console.log('[UPDATE_COMPONENT_PARAMS] payload', payload)

      try {
        resp = await axios.patch(
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
            }
          }
        )
      } catch (e) {
        error = e
      }
      console.log('[UPDATE_COMPONENT_PARAMS]', resp, error)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        commit('SET_DESIGN_CHANGES', { field: 'componentParams', value: false })
      }
    },
    async FETCH_LOYALTY_PORTAL_TEMPlATE_DATA ({ rootState, state, commit }) {
      let resp
      let error

      const endpoint = `${process.env.VUE_APP_CRADLE_HOST}/api/v1/hotels/dash/customer-portal-config/${state.groupUUID}/?loyalty_portal_enabled=True&template_type__in=Portal`

      try {
        resp = await axios.get(endpoint, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
      } catch (e) {
        error = e
      }
      console.log('[FETCH_LOYALTY_PORTAL_TEMPlATE_DATA]', resp, error)
      if (!error) {
        const { modifiedConfig, params, templateFieldPermissions, otherComponents, preferenceComponents } = parseGroupConfig(resp)
        commit('SET_GROUP_CONFIG', modifiedConfig)
        commit('SET_COMPONENT_PARAMS', params)
        commit('SET_INITIAL_COMPONENT_PARAMS', params)
        commit('SET_TEMPLATE_FIELD_PERMISSIONS', templateFieldPermissions)
        commit('SET_OTHER_COMPONENTS', otherComponents)
        commit('SET_PREFERENCE_COMPONENTS', preferenceComponents)
      }

      return { error }
    },
  }
}
