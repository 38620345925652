import Vue from 'vue'
import axios from 'axios'
import endpoints from '@/config/endpoints'
import QueryString from 'query-string'
import { pagination } from '@/store/utils/pagination'
import { CampaignParser, SegmentParser } from '@/parsers'
import { ParserUtils } from '@/parsers/utils'
import { campaignTypeOptions, campaignStateOptions, SEND_IMMEDIATELY } from '@/constants'
import { cloneDeep, uniq } from 'lodash'
import moment from 'moment'
import { CampaignUtils } from '@/utils/campaign'
import { EnterAValidUrlErrorHandler } from '@/lib/error-handlers'
import { getWorkflowMap, parseChildrenToIds, createDagFromWorkflow } from '@/utils/dag'

const { TIME, EVENT } = campaignTypeOptions
const { DRAFT } = campaignStateOptions
const { SET_PAGE_TOTAL } = pagination.keys
const POST = 'post'
const PATCH = 'patch'
const GET = 'get'
const ID = 'id'

const BASE_CAMPAIGN = {
  type: undefined,
  id: undefined,
  uuid: undefined,
  hotelId: undefined,
  name: '',
  segmentIds: [],
  rewardAlgoId: undefined,
  state: DRAFT,
  ctaLink: '',
  enabled: false,
  active: false,
  dag: {
    id: undefined,
    uuid: '',
    children: [],
    parent: undefined,
    created: '',
    modified: '',
    ctaLink: undefined,
    ctaRewardNotBeforeTime: undefined,
    ctaRewardExpirationTime: undefined,
    isPromotion: undefined,
    campaignId: undefined,
    param: '',
    rewardAlgoId: undefined,
    state: 'dr',
    emailDesignId: undefined,
    displayName: undefined,
    // the type is the same as the campaign type but represented
    // as a number. when creating/updating a campaign, the nested dag
    // must also be a number.
    triggerTypeId: undefined,
  },
  workflowEnabled: false,
}

export default {
  namespaced: true,
  state: {
    segments: {},
    segmentFetchSuccess: false,
    campaignFetchSuccess: false,
    campaigns: {},
    campaign: cloneDeep(BASE_CAMPAIGN),
    newestNodeIds: [],
    pageInfo: {
      page: 1,
      pageSize: 10,
      total: undefined,
      index: 0,
    },
    triggerTypes: {},
    /**
     * dag is the state.campaign dag represented as an object
     * keyed on ID with the `children` referenced as ID's rather
     * than nested dags
     */
    dag: {},
    /**
     * where we assemble a new node before making create request
     * or where we copy the value of an existing node before editing it.
     */
    activeNode: {},
    customMilestoneImage: null,
    /**
     * initial sent datetime from campaign.dag.params from cradle
     */
    sentDateTime: ''
  },
  getters: {
    SEGMENTS_LIST (state) {
      return Object.values(state.segments)
    },
    CAMPAIGNS_LIST (state) {
      return Object.values(state.campaigns) // TODO sort
    },
    // sorted by last modified
    WORKFLOWS_LIST (state, getters) {
      return getters.CAMPAIGNS_LIST
        .filter(campaign => campaign.workflowEnabled)
        .sort((a, b) => {
          const date1 = new Date(a.modified)
          const date2 = new Date(b.modified)

          return date2 - date1
        })
    },
    GET_CAMPAIGN (state) {
      return ({ id }) => state.campaigns[id]
    },
    RUN_TEST_TRIGGER_TYPE_ID (_state, getters) {
      const runTestObj = getters.TRIGGER_TYPES_LIST.find(obj => obj.category === 'Test Event')
      return runTestObj ? runTestObj.id : null
    },
    IS_EVENT_BASED (state) {
      return CampaignUtils.isEventBased(state.campaign)
    },
    IS_TIME_BASED (state) {
      return CampaignUtils.isTimeBased(state.campaign)
    },
    GET_CAMPAIGN_PAYLOAD (state, getters, rootState, rootGetters) {
      /**
       * @param {string | void} campaignName - allows override of campaign name. used to break two-way binding
       * of campaign.name in workflow container.
       * @param {boolean} activeNode - will source fields from state.activeNode rather than state.campaign
       * @param {boolean} workflow - used for logic in generating the campaign.dag
       */
      return ({ active, campaignName, useActiveNode = false, workflow = false }) => {
        const hotel = state.campaign.hotelId || rootGetters.CRADLE_HOTEL_ID

        let dag
        if (workflow) {
          if (useActiveNode) {
            dag = {
              parent: state.activeNode.parent,
              id: state.activeNode.id,
              template: state.activeNode.emailDesignId,
              cta_link: state.activeNode.ctaLink,
              cta_reward_not_before_time: state.activeNode.ctaRewardNotBeforeTime,
              cta_reward_expiration_time: state.activeNode.ctaRewardExpirationTime,
              display_name: state.activeNode.displayName,
              event_type: state.activeNode.triggerTypeId,
              event_campaign: state.campaign.id,
              param: state.activeNode.param,
              reward_algorithm: state.activeNode.rewardAlgoId,
              state: state.activeNode.state,
              operator: state.activeNode.operator,
              /**
               * use will be creating campaign and first node at same time
               * so there will be no child nodes. when user goes to add second node to
               * dag we use CREATE_OR_UPDATE_NODE.
               */
              children: []
            }
          } else {
            const raw = createDagFromWorkflow(cloneDeep(state.dag))
            dag = CampaignParser.inverseParseNodes(raw)
          }
        } else {
          // for event-based campaigns
          dag = {
            id: state.campaign.dag.id,
            parent: state.campaign.dag.parent,
            state: state.campaign.dag.state,
            template: state.campaign.dag.emailDesignId,
            cta_link: state.campaign.dag.ctaLink,
            cta_reward_not_before_time: state.campaign.dag.ctaRewardNotBeforeTime,
            cta_reward_expiration_time: state.campaign.dag.ctaRewardExpirationTime,
            display_name: state.campaign.dag.displayName,
            event_campaign: state.campaign.id,
            event_type: state.campaign.dag.triggerTypeId,
            param: state.campaign.dag.param,
            reward_algorithm: state.campaign.dag.rewardAlgoId,
            // event-based campaigns do not support more than 1 node in dag
            children: []
          }
        }

        const payload = {
          active,
          hotel,
          segments: state.campaign.segmentIds,
          dag,
          id: state.campaign.id,
          name: campaignName || state.campaign.name,
          workflow_enabled: state.campaign.workflowEnabled,
        }

        return payload
      }
    },
    GET_NODE_PAYLOAD (state) {
      return {
        event_campaign: state.campaign.id,
        id: state.activeNode.id,
        parent: state.activeNode.parent,
        children: state.activeNode.children.map(id => {
          const child = state.dag[id]
          return {
            id,
            // cradle requirement for update
            event_type: child?.triggerTypeId
          }
        }),
        template: state.activeNode.emailDesignId,
        cta_link: state.activeNode.ctaLink,
        cta_reward_not_before_time: state.activeNode.ctaRewardNotBeforeTime,
        cta_reward_expiration_time: state.activeNode.ctaRewardExpirationTime,
        event_type: state.activeNode.triggerTypeId,
        param: state.activeNode.param,
        reward_algorithm: state.activeNode.rewardAlgoId,
        display_name: state.activeNode.displayName,
        operator: state.activeNode.operator || 'no',
        is_promotion: state.activeNode.isPromotion
      }
    },
    GET_CAMPAIGN_TRIGGER (state) {
      return ({ id }) => {
        if (id) {
          return state.triggerTypes[id]
        }
      }
    },
    /**
     * returns the trigger configuration object of the campaign in view
     */
    CAMPAIGN_TRIGGER (state, getters) {
      return getters.GET_CAMPAIGN_TRIGGER({ id: state.campaign.dag.triggerTypeId })
    },
    CAMPAIGN_TRIGGER_IS_SEND_IMMEDIATELY (state, getters) {
      return getters.CAMPAIGN_TRIGGER && getters.CAMPAIGN_TRIGGER.name === SEND_IMMEDIATELY
    },
    TRIGGER_TYPES_LIST (state) {
      return Object.values(state.triggerTypes)
    },
    AUTO_TRIGGER_IDS (state, getters) {
      return [getters.AUTO_RELEASE_ID, getters.AUTO_SELECT_ID]
    },
    EVENT_TRIGGERS (state, getters) {
      return getters.TRIGGER_TYPES_LIST.filter(trigger => trigger.type === EVENT)
    },
    // event triggers only for a campaign and not a workflow
    CAMPAIGN_EVENT_TRIGGERS (_, getters) {
      return getters.EVENT_TRIGGERS.filter(trigger => {
        return [
          'Profile Event',
          'Booking Event',
        ].includes(trigger.category)
      })
    },
    TIME_TRIGGERS (state, getters) {
      return getters.TRIGGER_TYPES_LIST.filter(trigger => trigger.type === TIME)
    },
    TRIGGER_TYPE_CATEGORIES (_, getters) {
      return uniq(
        getters.TRIGGER_TYPES_LIST.map(triggerType => triggerType.category)
      )
    },
    ACTIVE_TRIGGER_TYPE (state) {
      const id = state.campaign.dag?.triggerTypeId
      if (id) return state.triggerTypes[id]
      return {}
    },
    /**
     * name is hard-coded in cradle
     */
    DELAY_ID (_, getters) {
      const type = getters.TRIGGER_TYPES_LIST.find(trigger => trigger.name === 'Delay')
      return type?.id
    },
    /**
     * name is hard-coded in cradle
     */
    SEND_EMAIL_ID (_, getters) {
      const type = getters.TRIGGER_TYPES_LIST.find(trigger => {
        return trigger.name === 'Send Email'
      })
      return type?.id
    },
    STATUS_LIST (_, getters) {
      return getters.TRIGGER_TYPES_LIST
        .filter(trigger => trigger.category === 'Status Event')
    },
    OFFER_LIST (_, getters) {
      return getters.TRIGGER_TYPES_LIST
        .filter(trigger => trigger.category === 'Special Offer Event')
    },
    STATUS_ID_LIST (_, getters) {
      return getters.STATUS_LIST.map(trigger => trigger.id)
    },
    GET_CAMPAIGN_EVENT_TYPE (state) {
      return ({ id }) => {
        if (id) return state.triggerTypes[id]
        return {}
      }
    },
    ACTIVE_NODE_PARENT (state) {
      if (typeof state.activeNode.parent === 'number') {
        return state.dag[state.activeNode.parent]
      }
    },
    AUTO_SELECT_ID (_, getters) {
      const type = getters.TRIGGER_TYPES_LIST.find(trigger => trigger.name === 'Auto-select Offer')
      return type?.id
    },
    AUTO_RELEASE_ID (_, getters) {
      const type = getters.TRIGGER_TYPES_LIST.find(trigger => trigger.name === 'Auto-release Offer')
      return type?.id
    },
    SCHEDULED_EVENT_IDS (_, getters) {
      return getters.TRIGGER_TYPES_LIST.filter(trigger => trigger.category === 'Scheduled Event')
        .map(trigger => trigger.id)
    },
  },
  mutations: {
    ...pagination.mutations,
    SET_CAMPAIGN (state, campaign) {
      state.campaign = cloneDeep(campaign)
    },
    SET_CAMPAIGN_NAME (state, name) {
      state.campaign.name = name
    },
    SET_CAMPAIGN_TYPE (state, type) {
      state.campaign.type = type
    },
    SET_CAMPAIGN_TRIGGER_TYPE_ID (state, id) {
      state.campaign.dag.triggerTypeId = id
    },
    SET_PARAM (state, param) {
      state.campaign.dag.param = param
    },
    SET_SEGMENT_IDS (state, ids) {
      state.campaign.segmentIds = ids
    },
    SET_CTA_LINK (state, link) {
      state.campaign.dag.ctaLink = link
    },
    SET_ALGO_ID (state, id) {
      state.campaign.dag.rewardAlgoId = id
    },
    SET_CAMPAIGNS (state, campaigns) {
      console.log('[SET_CAMPAIGNS]', campaigns)
      state.campaigns = campaigns
    },
    ADD_TO_CAMPAIGNS (state, campaign) {
      if (campaign.id) {
        state.campaigns[campaign.id] = campaign
      }
    },
    SET_SEGMENTS (state, segments) {
      state.segments = segments
    },
    CLEAR_CAMPAIGNS (state) {
      state.campaigns = {}
    },
    SET_SEGMENT_FETCH_SUCCESS (state, bool) {
      state.segmentFetchSuccess = bool
    },
    SET_CAMPAIGN_FETCH_SUCCESS (state, bool) {
      state.campaignFetchSuccess = bool
    },
    RESET_CAMPAIGN (state) {
      state.campaign = cloneDeep(BASE_CAMPAIGN)
    },
    RESET_DAG (state) {
      state.dag = {}
    },
    MERGE_SEGMENTS (state, segments) {
      state.segments = { ...state.segments, ...segments }
    },
    MERGE_CAMPAIGN (state, campaign) {
      Vue.set(state.campaigns, campaign.id, campaign)
    },
    SET_EMAIL_DESIGN_ID (state, id) {
      state.campaign.dag.emailDesignId = id
    },
    SET_TRIGGER_TYPES (state, types) {
      state.triggerTypes = types
    },
    SET_CUSTOM_MILESTONE_IMAGE (state, image) {
      state.customMilestoneImage = image
    },
    RESET_CUSTOM_MILESTONE_IMAGE (state) {
      state.customMilestoneImage = null
    },
    REMOVE_NODE (state, { id, deleteChildren = false }) {
      try {
        const node = state.dag[id]

        if (!node) {
          console.log('[REMOVE_NODE] cannot find node to remove:', id)
          return
        }

        // does not support deleting root/trigger node
        if (!node.parent) {
          return
        }

        // remove node's id from parent's children list
        const idx = state.dag[node.parent].children.indexOf(id)
        Vue.delete(state.dag[node.parent].children, idx)

        if (deleteChildren) {
          const idList = []
          const makeList = (children) => {
            children.forEach(childId => {
              const node = state.dag[childId]
              if (!node) return

              idList.push(node.id)

              if (node.children.length > 0) {
                makeList(node.children)
              }
            })
          }

          // make list of node id's to remove from state.dag
          makeList(node.children)
          idList.forEach(id => Vue.delete(state.dag, id))
        } else {
        // update parent with new children
          node.children.forEach((child) => {
            state.dag[node.parent].children.push(child)
          })
          // update all children with new parent
          node.children.forEach((id) => {
            Vue.set(state.dag[id], 'parent', node.parent)
          })
        }
        // remove node from store
        Vue.delete(state.dag, node.id)
      } catch (e) {
        console.log('REMOVE_NODE error', e)
      }
    },
    ADD_NODE (state, { node, isSplitPath }) {
      Vue.set(state.dag, node.id, node)

      // find parent, update children
      const parentId = node.parent

      if (parentId) {
        let children
        /**
         * if split path then we preserve the children and add to it
         * else we replace the children with the node being added.
         */
        if (isSplitPath) {
          children = [
            node.id,
            ...state.dag[parentId].children,
          ]
        } else {
          children = [node.id]
        }

        Vue.set(
          state.dag[parentId],
          'children',
          children
        )
      }
      // update all children's parent to point at node
      node.children.forEach((id) => {
        Vue.set(
          state.dag[id],
          'parent',
          node.id
        )
      })
    },
    SET_DAG (state, dag) {
      state.dag = dag
    },
    SET_CAMPAIGN_WORKFLOW_ENABLED (state, boolean) {
      state.campaign.workflowEnabled = boolean
    },
    SET_ACTIVE_NODE (state, node) {
      state.activeNode = { ...node }
    },
    SET_ACTIVE_NODE_CTA_LINK (state, ctaLink) {
      state.activeNode.ctaLink = ctaLink
    },
    SET_ACTIVE_NODE_ALGO_ID (state, id) {
      state.activeNode.rewardAlgoId = id
    },
    SET_ACTIVE_NODE_IS_PROMOTION (state, isPromotion) {
      state.activeNode.isPromotion = isPromotion
    },
    SET_ACTIVE_NODE_PARAM (state, param) {
      state.activeNode.param = param
    },
    SET_ACTIVE_NODE_PARENT (state, id) {
      state.activeNode.parent = id
    },
    SET_NEWEST_NODE_ID (state, id) {
      state.newestNodeIds.push(id)
    },
    REMOVE_NEWEST_NODE_ID (state, id) {
      const idx = state.newestNodeIds.indexOf(id)
      if (idx !== -1) {
        Vue.delete(state.newestNodeIds, idx)
      }
    },
    RESET_ACTIVE_NODE (state) {
      Vue.set(state, 'activeNode', {
        children: [],
        parent: undefined,
        ctaLink: undefined,
        ctaRewardNotBeforeTime: undefined,
        ctaRewardExpirationTime: undefined,
        campaignId: undefined,
        param: '',
        rewardAlgoId: undefined,
        state: 'dr',
        emailDesignId: undefined,
        triggerTypeId: undefined,
        operator: 'no',
        eventType: undefined,
        displayName: undefined,
        isPromotion: undefined
      })
    },
    SET_ACTIVE_NODE_TRIGGER_TYPE_ID (state, id) {
      state.activeNode.triggerTypeId = id
    },
    SET_ACTIVE_NODE_EMAIL_DESIGN_ID (state, id) {
      state.activeNode.emailDesignId = id
    },
    SET_ACTIVE_NODE_ID (state, id) {
      state.activeNode.id = id
    },
    SET_ACTIVE_NODE_OPERATOR (state, operator) {
      console.log('[SET_ACTIVE_NODE_OPERATOR]', operator)
      Vue.set(state.activeNode, 'operator', operator)
    },
    SET_ACTIVE_NODE_DISPLAY_NAME (state, displayName) {
      console.log('[SET_ACTIVE_NODE_OFFER_NAME]', displayName)
      Vue.set(state.activeNode, 'displayName', displayName)
    },
    SET_SENT_DATETIME (state, campaign) {
      /* used in Campaign Editor setup */
      state.sentDateTime = campaign.dag?.param
    },
    SET_PAGE (state, page) {
      state.pageInfo.page = page
    },
    SET_PAGE_SIZE (state, pageSize) {
      state.pageInfo.pageSize = pageSize
    },
    SET_PAGE_INDEX (state, index) {
      state.pageInfo.index = index
    }
  },
  actions: {
    async CREATE_OR_UPDATE_CUSTOM_MILESTONE_IMAGE (
      { rootState, dispatch, state },
      { id }
    ) {
      let endpoint
      let method

      if (id) {
        endpoint = `${endpoints.cradle.eventNodeFile}${id}/`
        method = PATCH
      } else {
        endpoint = `${endpoints.cradle.eventNodeFile}`
        method = POST
      }

      let response
      let error

      const payload = new FormData()
      payload.set('mobile_display_image', state.customMilestoneImage)

      try {
        response = await axios[method](
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (err) {
        error = err.response.data
      }

      console.log('[CREATE_OR_UPDATE_CUSTOM_MILESTONE_IMAGE]', response, error)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        dispatch(
          'messages/ADD_SUCCESS',
          { message: 'Workflow was saved.' },
          { root: true }
        )
      }

      return {
        error,
        response
      }
    },
    async FETCH_CUSTOM_MILESTONE_IMAGE (
      { rootState, dispatch, commit },
      { id }
    ) {
      let response, error

      try {
        response = await axios[GET](
          `${endpoints.cradle.eventNodes}${id}/`,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (err) {
        error = err.response.data
      }

      console.log('[FETCH_CUSTOM_MILESTONE_IMAGE]', response, error)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        const uploadedImage = response.data?.mobile_display_image
        if (uploadedImage) {
          const s3UploadDelineator = 'CampaignEventNode/'

          commit(
            'SET_CUSTOM_MILESTONE_IMAGE',
            {
              mobile_display_image: uploadedImage,
              name: uploadedImage.substring(uploadedImage.indexOf(s3UploadDelineator) + s3UploadDelineator.length)
            }
          )
        }
      }

      return {
        error,
        response
      }
    },
    async DELETE_CUSTOM_MILESTONE_IMAGE (
      { rootState, dispatch },
      { id }
    ) {
      let response, error

      const payload = new FormData()
      payload.set('mobile_display_image', new File([], ''))

      try {
        response = await axios[PATCH](
          `${endpoints.cradle.eventNodeFile}${id}/`,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (err) {
        error = err.response.data
      }

      console.log('[DELETE_CUSTOM_MILESTONE_IMAGE]', response, error)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        dispatch(
          'messages/ADD_SUCCESS',
          { message: 'Image successfully removed' },
          { root: true }
        )
      }

      return {
        error,
        response
      }
    },
    ADD_NEWEST_NODE_IDS ({ commit }, id) {
      commit('SET_NEWEST_NODE_ID', id)
      setTimeout(() => commit('REMOVE_NEWEST_NODE_ID', id), 5000)
    },
    async FETCH_SEGMENTS (
      { commit, dispatch, rootState },
      {
        page = 1,
        pageSize = 10,
        fetchAll = false,
        nextUrl
      }
    ) {
      commit('SET_SEGMENT_FETCH_SUCCESS', false)
      const queryString = QueryString.stringify({
        page: page,
        page_size: pageSize,
        hotel__legacy_id: rootState.route.params.hotelId
      })
      let endpoint = `${endpoints.cradle.segmentRoute}?${queryString}`

      if (nextUrl) {
        endpoint = nextUrl.replace('http', 'https')
      }
      let response
      let error

      try {
        response = await axios.get(endpoint, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
      } catch (err) {
        error = new Error(
          JSON.stringify(err.response.data)
        )
      }

      if (error) {
        return console.log('error fetching segments... snackbar?', error)
      }

      console.log('FETCH_SEGMENTS]:', response.data.results)
      const parsedSegments = ParserUtils.getParsedObject({
        rawList: response.data.results,
        parser: SegmentParser.parseSegment,
        indexKey: ID,
      })
      commit('SET_SEGMENTS', parsedSegments)
      commit('SET_SEGMENT_FETCH_SUCCESS', true)

      if (fetchAll) {
        if (response.data.next) {
          await dispatch('FETCH_SEGMENTS', { nextUrl: response.data.next, fetchAll: true })
          const parsedSegments = ParserUtils.getParsedObject({
            rawList: response.data.results,
            parser: SegmentParser.parseSegment,
            indexKey: ID,
          })
          commit('MERGE_SEGMENTS', parsedSegments)
        } else {
          commit('SET_SEGMENT_FETCH_SUCCESS', true)
        }
      } else {
        commit('SET_SEGMENT_FETCH_SUCCESS', true)
      }
    },
    async FETCH_CAMPAIGNS (
      { commit, dispatch, rootState },
      { id, page, pageSize, workflows = false }
    ) {
      commit('SET_CAMPAIGN_FETCH_SUCCESS', false)
      const queryString = QueryString.stringify({
        page: page,
        page_size: pageSize,
        hotel__legacy_id: rootState.route.params.hotelId,
        workflow_enabled: workflows
      })
      const endpoint = id
        ? `${endpoints.cradle.eventCampaign}${id}/`
        : `${endpoints.cradle.eventCampaign}?${queryString}`

      let response
      let error

      try {
        response = await axios.get(endpoint, {
          headers: {
            Authorization: 'Bearer ' + rootState.authController.getCognitoToken()
          }
        })
      } catch (err) {
        console.log('err', err)
        error = err.response.data
      }

      console.log('[FETCH_CAMPAIGNS]', error || response)

      if (error) {
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        let rawList
        if (response.data.results) {
          rawList = response.data.results
        } else {
          rawList = [response.data]
        }

        const parsedCampaigns = ParserUtils.getParsedObject({
          rawList,
          parser: CampaignParser.parseCampaign,
          indexKey: ID,
        })

        commit('SET_CAMPAIGNS', parsedCampaigns)

        if (response?.data.count) {
          commit(SET_PAGE_TOTAL, response.data.count)
        }
        commit('SET_CAMPAIGN_FETCH_SUCCESS', true)
      }

      return { error, data: response?.data }
    },
    /**
     * @param {active} Boolean - used to activate event-based campaigns
     */
    async CREATE_OR_UPDATE_CAMPAIGN (
      { commit, dispatch, rootState, getters },
      {
        id,
        showSuccess = false,
        active = undefined,
        campaignName = undefined, // the value in the component textfield
        useActiveNode = false,
        workflow = false
      }
    ) {
      console.log('[CREATE_OR_UPDATE_CAMPAIGN] active', active)
      let endpoint
      let method
      if (!id) {
        endpoint = `${endpoints.cradle.eventCampaign}`
        method = POST
      } else {
        endpoint = `${endpoints.cradle.eventCampaign}${id}/`
        method = PATCH
      }

      const payload = getters.GET_CAMPAIGN_PAYLOAD({
        active,
        campaignName,
        useActiveNode,
        workflow,
      })
      console.log('[CREATE_OR_UPDATE_CAMPAIGN] payload', payload)
      // TODO move into util
      if (
        typeof payload.cta_link === 'string' &&
        payload.cta_link !== '' &&
        !payload.cta_link.match(/^https?:\/\//i)
      ) {
        payload.cta_link = 'http://' + payload.cta_link
      }
      console.log('[CREATE_OR_UPDATE_CAMPAIGN]', JSON.stringify(payload, null, 2))
      let error
      let response
      try {
        response = await axios[method](
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (err) {
        error = err.response.data
        if (error.dag?.cta_link) {
          error.dag.cta_link[0] = EnterAValidUrlErrorHandler.friendlyMessage
        }
        dispatch('messages/ADD_ERROR', error, { root: true })
      }

      if (!error) {
        const campaign = CampaignParser.parseCampaign(response.data)
        commit('MERGE_CAMPAIGN', campaign)
        commit('SET_CAMPAIGN', campaign)
        if (workflow) dispatch('SETUP_DAG')
      }

      if (!error && showSuccess) {
        const entity = workflow ? 'Workflow' : 'Campaign'
        dispatch(
          'messages/ADD_SUCCESS',
          { message: `${entity} was saved.` },
          { root: true }
        )
      }

      /**
       * break caching for next fetch of campaigns
       */
      const refreshSession = await rootState.authController.refreshSession()
      if (refreshSession.error) {
        dispatch('messages/ADD_ERROR', refreshSession.error, { root: true })
      }

      return {
        error,
        data: response
      }
    },
    async SET_EMAIL_DESIGN_ID_AND_MAYBE_HTML ({ commit, dispatch, rootGetters }, { id }) {
      const emailDesign = rootGetters['edm/GET_EMAIL_DESIGN']({ id })
      if (emailDesign && emailDesign.html === undefined) {
        await dispatch('edm/FETCH_EMAIL_STRING_AND_MERGE',
          { id: id, url: emailDesign.htmlUrl },
          { root: true }
        )
      }
      commit('SET_EMAIL_DESIGN_ID', id)
    },
    async FETCH_TRIGGER_TYPES ({ commit, rootState }) {
      let error
      let response

      const queryString = QueryString.stringify({
        page: 1,
        page_size: 1000,
      })

      const endpoint = `${endpoints.cradle.eventType}?${queryString}`

      try {
        response = await axios.get(
          endpoint,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
        const triggers = ParserUtils.getParsedObject({
          rawList: response.data.results,
          parser: CampaignParser.parseTriggerTypes,
          indexKey: ID
        })
        commit('SET_TRIGGER_TYPES', triggers)
      } catch (err) {
        error = err
      }
      console.log('[FETCH_TRIGGER_TYPES]', response, error)
    },
    async TOGGLE_CAMPAIGN_ACTIVE ({ getters, commit, dispatch, rootState }, { id, active, detail }) {
      let error
      let response

      const campaign = getters.GET_CAMPAIGN({ id })

      const endpoint = `${endpoints.cradle.eventCampaign}${campaign.id}/`
      const payload = { active }
      try {
        response = await axios.patch(
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
        const updated = CampaignParser.parseCampaign(response.data)
        if (detail) {
          commit('SET_CAMPAIGN', updated)
        } else {
          commit('ADD_TO_CAMPAIGNS', updated)
        }
      } catch (err) {
        error = err
        dispatch('messages/ADD_ERROR', error, { root: true })
      }
      console.log('[TOGGLE_CAMPAIGN_ACTIVE]', error, response)

      return {
        error,
        data: response
      }
    },
    HANDLE_SEND_IMMEDIATELY_SCHEDULE_DATETIME ({ commit, getters }) {
      if (getters.CAMPAIGN_TRIGGER_IS_SEND_IMMEDIATELY) {
        const now = moment().toISOString()
        commit('SET_PARAM', now)
      }
    },
    /**
     * @note to be used when there are incoming changes to state.campaign.
     * this action will take campagn.dag and set up state.dag with a
     * map/dict of all nodes in the dag.
     */
    SETUP_DAG ({ state }) {
      const dag = getWorkflowMap(cloneDeep(state.campaign))
      Object.keys(dag).forEach(key => {
        Vue.set(state.dag, key, dag[key])
      })
    },
    async CREATE_OR_UPDATE_NODE (
      { rootState, commit, dispatch, getters, state },
      { showSuccess = true, isSplitPath = false }
    ) {
      console.log('[CREATE_OR_UPDATE_NODE] isSplitPath', isSplitPath)
      /**
       * use state.activeNode to create
       * on success
       * use ADD_NODE to add to map
       */
      let endpoint
      let method

      const { id } = state.activeNode

      if (id) {
        endpoint = `${endpoints.cradle.eventNodes}${id}/`
        method = PATCH
      } else {
        endpoint = `${endpoints.cradle.eventNodes}`
        method = POST
      }

      const payload = getters.GET_NODE_PAYLOAD

      let response
      let error

      try {
        response = await axios[method](
          endpoint,
          payload,
          {
            headers: {
              Authorization: 'Bearer ' + rootState.authController.getCognitoToken(),
              'Content-Type': 'application/json'
            }
          }
        )
      } catch (err) {
        error = err.response.data
      }
      console.log('[CREATE_OR_UPDATE_NODE]', response, error)

      let parsedNode

      if (error) {
        if (error.cta_link) {
          error.cta_link[0] = EnterAValidUrlErrorHandler.friendlyMessage
        }
        dispatch('messages/ADD_ERROR', error, { root: true })
      } else {
        /**
         * parse node, update children to be ID's then use ADD_NODE to add
         * node to state.dag and to update parent and potential child node pointers.
         */
        parsedNode = CampaignParser.parseNodes(response.data)
        parsedNode.children = parseChildrenToIds(parsedNode.children)
        commit(
          'ADD_NODE',
          {
            isSplitPath,
            node: parsedNode,
          }
        )

        /**
         * sync all fields on activeNode after creating node in db
         */
        if (!state.activeNode.id) {
          commit('SET_ACTIVE_NODE', parsedNode)
        }

        if (showSuccess) {
          dispatch(
            'messages/ADD_SUCCESS',
            { message: 'Workflow was saved.' },
            { root: true }
          )
        }
      }

      return {
        error,
        data: parsedNode
      }
    },
  },
}
