import store from '@/store'
import { userViews } from '@/constants'
import {
  adminDashboard,
  merchantDashboard,
  hotelDashboard
} from '@/config/nav/adminToggleNavConfigs'
import { NavConfig } from '@/types'

export const disabled: { [k: string]: () => boolean } = {
  hss: () => !store.getters.HSS_ACCESS,
  edm: () => !store.getters.EDM_ACCESS,
  rewardAlgos: () => !store.getters.REWARD_ALGO_ACCESS,
  flex: () => !store.getters.FLEX_ACCESS,
  oprm: () => !store.getters.OPRM_ACCESS
}

export const hotelNavConfig: NavConfig = {
  idParam: {
    key: 'hotelId',
    fallbackGetter: 'HOTEL_ID',
  },
  topMenuLinks: [
    {
      copy: 'Home',
      routeName: userViews.DASHBOARD,
    },
    /**
     * admins & group users on convert will see Group Reporting
     */
    {
      copy: 'Group Reporting',
      routeName: userViews.GROUP,
      show: () => store.getters.SHOW_GROUP_REPORTING
    },
    /**
     * admins & group users on retain will Retain Reporting
     */
    {
      copy: 'Retain Reporting',
      show: () => store.getters.SHOW_RETAIN_REPORTING,
      style: {
        width: 260
      },
      children: [
        {
          copy: 'Growth & Scale',
          description: 'See how your program is doing over time',
          routeName: userViews.GROWTH_SCALE
        },
        {
          copy: 'Program Impact',
          description: 'Measure the impact of your program',
          routeName: userViews.PROGRAM_IMPACT
        },
        {
          copy: 'Progam Demographics',
          description: 'Get stats on your most loyal customers',
          routeName: userViews.PROGRAM_DEMOGRAPHICS
        },
        {
          copy: 'Loyalty Members',
          description: 'Dig deeper into each guest profile',
          routeName: userViews.LOYALTY_MEMBERS
        },
        {
          copy: 'Loyalty Portal Traffic',
          description: 'Get in-depth portal traffic analyses',
          routeName: userViews.LOYALTY_TRAFFIC
        }
      ]
    }
  ],
  links: [
    {
      copy: 'Rewards',
      routeName: null,
      icon: ['fas', 'gift'],
      children: [
        {
          copy: 'Reward Manager',
          routeName: userViews.OP_REWARDS,
          disabled: disabled.oprm,
        },
        {
          copy: 'Advanced Algorithms',
          routeName: userViews.REWARD_ALGOS,
          disabled: disabled.edm,
        },
        {
          copy: 'Booking Algorithms',
          routeName: userViews.HOTEL_REWARD_ALGOS,
          disabled: disabled.rewardAlgos,
        },
        {
          copy: 'Reward Metrics',
          routeName: userViews.REWARD_METRICS,
          disabled: disabled.oprm,
        }
      ]
    },
    {
      copy: 'Design & Branding',
      routeName: null,
      icon: ['fas', 'paint-brush'],
      children: [
        {
          copy: 'Email Designer',
          routeName: userViews.EMAIL_DESIGNS,
          disabled: disabled.edm,
        },
        {
          copy: 'Embedded Design Editor',
          routeName: userViews.FUNNEL_UXS,
          disabled: disabled.hss,
        }, {
          copy: 'Loyalty Portal Editor',
          routeName: userViews.LOYALTY_PORTAL_EDITOR,
          disabled: () => false,
          show: () => store.getters.IS_ADMIN && Boolean(store.getters.LOYALTY_PORTAL_URL)
        }
      ]
    },
    {
      copy: 'Workflows',
      routeName: null,
      icon: ['fas', 'network-wired'],
      children: [
        {
          copy: 'Workflow Manager',
          routeName: userViews.WORKFLOWS,
          disabled: disabled.edm, // email marketing permission
        },
        {
          copy: 'Workflow Metrics',
          routeName: userViews.CAMPAIGN_METRICS,
          disabled: disabled.edm, // email marketing permission
        },
      ]
    },
    {
      copy: 'Flex On-Demand',
      routeName: null,
      icon: ['fas', 'envelope-open-text'],
      children: [
        {
          copy: 'Send On-Demand Rewards',
          routeName: userViews.FLEX_NEW,
          disabled: disabled.flex,
        },
        {
          copy: 'On-Demand Metrics',
          routeName: userViews.ON_DEMAND_METRICS,
          disabled: disabled.flex,
        },
      ]
    },
    /**
     * property reporting links not shown if they are locked/disabled.
     */
    {
      copy: 'Property Reporting',
      routeName: null,
      icon: ['far', 'chart-bar'],
      disabled: () => store.getters.IS_VANITY_PROPERTY,
      show: () => store.getters.IS_ADMIN ||
        (store.getters.REPORTING_ACCESS && store.getters.IS_CONVERT),
      children: [
        // temporarily removed for all users
        // {
        //   copy: 'Control/Exposed Test',
        //   routeName: userViews.SPLIT_TESTS,
        // },
        {
          copy: 'Program Engagement',
          routeName: userViews.PROGRAM_ENGAGEMENT,
        },
        {
          copy: 'ROI',
          routeName: userViews.ROI,
        },
        {
          copy: 'Revenue Metrics',
          routeName: userViews.REVENUE_METRICS,
        },
        {
          copy: 'Upcoming Bookings',
          routeName: userViews.UPCOMING_BOOKINGS,
        },
      ]
    },
    {
      copy: 'Property Settings',
      show: () => true,
      routeName: userViews.ACCOUNT,
      icon: ['fas', 'cog'],
    },
  ],
  adminToggles: [
    // order affects rendering in dropdown
    merchantDashboard,
    adminDashboard,
  ],
  currentApp: hotelDashboard.copy,
  showCopyHeader: true,
}
